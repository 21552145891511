import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://3432c273a1c5d30b35fb38ff423600f4@o4507416825233408.ingest.us.sentry.io/4508130698592256',

  tracesSampleRate: 1.0,


});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
