import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/(legal)/(docs)/about": [7,[3]],
		"/actions/change-password": [39],
		"/(app)/be-writer": [16],
		"/(app)/book-publishing/download/[order_id]": [~18],
		"/(app)/book-publishing/[id]": [~17],
		"/(app)/books/[slug]": [~19,[2,4]],
		"/(app)/books/[slug]/reviews": [~20,[2,4]],
		"/(app)/bundles": [~21,[2]],
		"/(app)/bundles/[id]": [~22,[2]],
		"/(app)/(protected)/cart": [~11,[2]],
		"/(app)/categories/[slug]": [~23,[2]],
		"/(app)/(protected)/checkout/[type]/[id]/[subscription_id]": [~12,[2]],
		"/(auth)/create-password": [~29,[5]],
		"/(auth)/deactivate-account": [~30,[5]],
		"/(auth)/deactivate-account/success": [~31,[5]],
		"/(auth)/delete-account": [~32,[5]],
		"/(auth)/delete-account/success": [~33,[5]],
		"/(app)/download-app": [~24,[2]],
		"/(app)/(protected)/favorites": [~13,[2]],
		"/(auth)/forgot-password": [~34,[5]],
		"/(app)/get-download-app-link": [~25],
		"/(app)/(legal)/(docs)/help": [8,[3]],
		"/(auth)/login": [~35,[5]],
		"/(app)/(protected)/my-library": [~14,[2]],
		"/(auth)/otp-verification": [~36,[5]],
		"/(app)/(legal)/(docs)/privacy-policy": [9,[3]],
		"/(app)/publishers/[username]": [~26,[2]],
		"/(auth)/register": [~37,[5]],
		"/(auth)/register/oauth/google": [~38,[5]],
		"/(app)/search": [~27,[2]],
		"/(app)/(legal)/(docs)/terms-conditions": [10,[3]],
		"/(app)/(protected)/transactions": [~15,[2]],
		"/(app)/writers/[username]": [~28,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';